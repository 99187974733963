import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChatMessageContext, useDispatchChatMessageContext } from 'src/contexts/ChatMessageContext';
import { RootState, dispatch, useSelector } from 'src/redux/store';
import { setSideBarChat } from 'src/redux/slices/sidebarSlice';
import snackbar from 'src/components-mars/Snackbar';
// hooks
import useSettings from 'src/hooks/useSettings';
import useMessage from 'src/hooks/useMessage';
import useSelection from 'src/hooks/useSelection';
import useRecorder from 'src/hooks/useRecorder2';

// import usePrivacyPolicy from 'src/hooks/usePrivacyPolicy';
import { useTranscribe } from 'src/hooks/useTranscribe';
import { azureWhisper } from 'src/models/transcribe-service';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { promptAPI } from 'src/apis/promptsAPI';
import { Prompts } from 'src/@types/prompt';
import { usePromptCard } from 'src/hooks/usePromptCard';
import { useApiError } from 'src/hooks/useApiError';
import { ChatCompletionContentPart } from 'openai/resources';
import { OriginImageList } from 'src/contexts/ChatMessageReducer';

export const useChatPageModel = () => {
  const { t, i18n } = useTranslation();
  const { themeMode } = useSettings();
  const theme = useTheme();
  const { onSnackbarClose, closeSnackbar } = snackbar();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const apiError = useApiError(t);
  // const { privacyPolicyText, open } = usePrivacyPolicy();
  // const text = privacyPolicyText?.ja;
  const [gptTip, setGptTip] = useState<boolean>(false);
  const [gptDetail, setGptDetail] = useState<boolean>(false);

  // context
  const {
    abortCntl,
    chatEnd,
    chatLog,
    chatMessages,
    inactivityTime,
    inputMessage,
    images,
    recordingStatus,
    selectId,
    skipCount,
    useChatType,
    isWebSearch,
    isTopWebSearch,
    handleChatEnd,
    handleChatLog,
    handlingErrorGetPreMessages,
    handleAbortCntl,
    handleInactivity,
    handleSwitchWebSearch,
    handleInputMessage,
    handleAttachedImages,
    handleIsNewChat,
    handleRecordingStatus,
    handleSelectId,
    handleSkipCount,
    handleUseChatType,
    handleChatHistoryWebSearch,
    handleRegistPromptUsageHistory,
    handleRegistPromptUsageHistoryAsync,
    homeMessage,
    handleSwitchHomeMessage,
    handleHomeToChat,
    homeToChat,
    topicFlag,
    handleTopicFlag,
  } = useChatMessageContext();
  const dispatchMessageChat = useDispatchChatMessageContext();

  // dispatch action
  // delete
  const dispatchActionDelete = () =>
    dispatchMessageChat({
      type: 'message/delete',
      payload: { role: 'user', content: '' },
    });

  // update
  const dispatchActionUpdate = (message: string) =>
    dispatchMessageChat({
      type: 'message/update',
      payload: {
        role: 'assistant',
        content: message,
      },
    });
  // add user
  const dispatchActionAddUser = (message: string | ChatCompletionContentPart[], originImg?: OriginImageList[]) =>
    dispatchMessageChat({
      type: 'message/add',
      payload: {
        role: 'user',
        content: message,
        originImg: originImg,
      },
    });
  // add assist
  const dispatchActionAddAssist = () =>
    dispatchMessageChat({
      type: 'message/add',
      payload: {
        role: 'assistant',
        content: ' ',
      },
    });
  // reset
  const dispatchActionReset = () =>
    dispatchMessageChat({
      type: 'message/addReset',
      payload: {
        role: 'user',
        content: '',
      },
    });
  // add edit
  const dispatchActionEdit = (message: string | ChatCompletionContentPart[], index: number) =>
    dispatchMessageChat({
      type: 'message/edit',
      payload: {
        role: 'user',
        content: message,
      },
      index: index,
    });

  // store
  const isLoading = useSelector((state: RootState) => state.chatGptState).isLoading;

  // const (use in chat page)
  const alertMessage = t('chat.text.cautions');
  const promptShowMessage = t('chat.text.showPrompt');
  const introduction = t('chat.text.introduction');
  const alertColor = themeMode === 'light' ? 'black' : 'white';
  const topMenuType = 'chat';
  const existChatMessage = chatMessages && chatMessages.length !== 0;
  const existSelectId = selectId !== '';

  const inputElement = createRef<HTMLInputElement>();
  const messagesEnd = useRef<null | HTMLDivElement>(null);

  // 処理待ち状態
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  // 翻訳結果テキスト
  const [transcript, setTranscript] = useState<string>('');

  const tokenOverMessage = () => {
    closeSnackbar();
    onSnackbarClose({
      color: 'error',
      msg: t('chat.message.error.contextLengthExceeded'),
      isPersist: true,
    });
  };

  const errorMessage = (e: any) => {
    console.log(e.message);
    if (e.message === 'context_length_exceeded') {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: t('chat.message.error.contextLengthExceeded'),
        isPersist: true,
      });
    } else if (e.message === 'error_in_bing') {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: t('common.message.error.bingError'),
        isPersist: true,
      });
    } else if (e.message === 'content_filter') {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: t('common.message.error.contentFilter'),
        isPersist: true,
      });
    } else if (e.message === 'more_than_one_url') {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: t('chat.message.error.moreThanOneUrl'),
        isPersist: true,
      });
    } else if (e.message === 'web_token_over') {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: t('chat.message.error.webTokenOver'),
        isPersist: true,
      });
    } else if (e.message.includes('Timeout')) {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: t('common.message.error.timeout'),
        isPersist: true,
      });
    } else if (!(e instanceof DOMException && e.name === 'AbortError')) {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: `${t('common.message.error.sendGPTMessage')} ${e}`,
        isPersist: true,
      });
    }
  };

  // hooks
  const { start, stop } = useRecorder();

  //
  // FIXME: useMinutesは議事録関連機能のため、将来的に動作が変更される可能性があります。
  //        そのため、文字起こしで使いたいのであればuseTranscribeを使用してください。引数はほぼ同じです。
  //
  const { transcribe } = useTranscribe();

  const startRecording = useCallback(async () => {
    await start();
  }, []);

  /**
   * 文字起こしする処理
   * @param file オーディオファイル
   */
  const onTranscribe = useCallback(
    async (file: File) => {
      setIsWaitingForResponse(true);

      try {
        const text = await transcribe(file, azureWhisper.service, 600, null);
        setTranscript(() => text);
      } catch (error) {
        const message = apiError.getErrorMessage(error);
        onSnackbarClose({
          color: 'error',
          msg: `${t('common.message.error.transcribe')} ${message}`,
          isPersist: true,
        });
      } finally {
        setIsWaitingForResponse(false);
      }
    },
    [useChatType, transcript, isWaitingForResponse],
  );

  const stopRecording = useCallback(async () => {
    try {
      const file = await stop();
      if (file !== null) {
        onTranscribe(file);
      }
    } catch (error) {
      console.error(error);
    }
  }, [onTranscribe, stop]);

  const {
    options: chatVersionOptions,
    onChangeOption,
    value,
  } = useSelection({
    useChatType,
    handleUseChatType,
  });
  const {
    progress,
    tokenLength,
    messageToken,
    newBtnLabel,
    newBtnOnClick,
    meatballsClick,
    anchorEl,
    meatballsClose,
    itemList,
    onRecordingStartButtonClick,
    onRecordingStopButtonClick,
    handleSendMessageButton,
    handleInputChange,
    // onKeyDownHandler,
    // startComposition,
    // endComposition,
    handleSendUpdateMessageButton,
    placeholder,
    anchorOriginTR,
    transformOriginBR,
    plugin,
    handlePaste,
    imgClearOne,
    inputFile,
    downloadImg,
  } = useMessage({
    updated: existChatMessage && chatEnd,
    handleInputMessage,
    handleAttachedImages,
    selectId,
    handleUseChatType,
    handleIsNewChat,
    handleSelectId,
    abortCntl,
    dispatchActionDelete,
    startRecording,
    stopRecording,
    handleRecordingStatus,
    inputMessage,
    images,
    isLoading,
    handleChatLog,
    dispatchActionUpdate,
    dispatchActionAddUser,
    dispatchActionAddAssist,
    dispatchActionReset,
    chatLog,
    useChatType,
    tokenOverMessage,
    handleChatEnd,
    chatMessages,
    handleAbortCntl,
    handleSkipCount,
    skipCount,
    errorMessage,
    dispatchActionEdit,
    handlingErrorGetPreMessages,
    isWebSearch,
    homeMessage,
    handleSwitchHomeMessage,
    handleSwitchWebSearch,
    topicFlag,
    handleTopicFlag,
  });

  const scrollToBottom = useCallback(() => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messagesEnd]);

  const [prompts, setPrompts] = useState<Prompts[]>([]);

  const getTop6Prompts = async () => {
    try {
      const { data } = await promptAPI.getPrompts(i18n.language, undefined, undefined, 6);
      setPrompts(data);
    } catch (e) {
      console.log(e);
    }
  };

  // side effect
  // showing side bar
  useEffect(() => {
    dispatch(setSideBarChat());
    setTimeout(() => {
      getTop6Prompts();
    }, 500);
  }, []);

  // timeout 30分
  let timer: string | number | NodeJS.Timeout | null | undefined = null;
  useEffect(() => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      if (selectId && selectId !== 'new') {
        handleInactivity();
        dispatchMessageChat({
          type: 'message/delete',
          payload: { role: 'user', content: '' },
        });
        onSnackbarClose({
          color: 'info',
          msg: t('chat.message.info.inactivity'),
          isPersist: true,
        });
      }
    }, inactivityTime);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [selectId, inputMessage, chatMessages]);

  /**
   * トップメニューを切り替えるときに、入力中のメッセージを削除しNewChatへ
   */
  useEffect(() => {
    if (homeToChat) handleHomeToChat(false);
    else handleInactivity();
    dispatchMessageChat({
      type: 'message/delete',
      payload: { role: 'user', content: '' },
    });
  }, [location.pathname]);

  // scrolling to bottom
  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  // focusing record
  useEffect(() => {
    if (inputMessage.length === 0 || transcript.length !== 0) {
      console.log('transcript⭐️', transcript);
      handleInputMessage(transcript);
      setTranscript('');
    }
    const node = inputElement.current;
    if (node) {
      node.focus();
    }
  }, [transcript]);

  // update recording status
  useEffect(() => {
    if (!isWaitingForResponse) {
      handleRecordingStatus('STOPPING');
    }
  }, [isWaitingForResponse]);

  const promptHook = usePromptCard({ handleInputMessage, handleRegistPromptUsageHistory, handleRegistPromptUsageHistoryAsync });

  const inputPrompt = async (promptId: number, updated: string[]) => {
    await promptHook.handleChatOpenButtonClickAsync(promptId, updated);
    getTop6Prompts();
  };

  return {
    // Page Flag
    existChatMessage,
    existSelectId,
    downloadImg,
    // Alert
    alertMessage,
    alertColor,
    topMenuType,
    // introduction message
    introduction,
    // Chat verison
    chatVersionOptions,
    onChangeOption,
    value,
    // using api
    isLoading,
    // token
    progress,
    tokenLength,
    messageToken,
    maxTokenLength: useChatType.maxTokenLength,
    chatModel: useChatType.label,
    // new btn
    newBtnLabel,
    newBtnOnClick,
    // meatballs
    themeMode,
    meatballsClick,
    anchorEl,
    meatballsClose,
    itemList,
    anchorOrigin: anchorOriginTR,
    transformOrigin: transformOriginBR,
    // recording
    recordingStatus,
    inputElement,
    onRecordingStartButtonClick,
    onRecordingStopButtonClick,
    // plugin
    isWebSearch,
    isTopWebSearch,
    handleSwitchWebSearch,
    handleChatHistoryWebSearch,
    // message
    messagesEnd,
    handleSendMessageButton,
    handleInputChange,
    // onKeyDownHandler,
    // startComposition,
    // endComposition,
    placeholder,
    inputMessage,
    handlePaste,
    images,
    imgClearOne,
    inputFile,
    // messages
    chatMessages,
    promptShowMessage,
    // edit
    selectId,
    handleSendUpdateMessageButton,
    plugin,
    isMobile,
    // privacyPolicy
    // privacyPolicyText,
    // text,
    open,
    gptTip: {
      tooltip: gptTip,
      tooltipHandler: setGptTip,
      modal: gptDetail,
      modalHandler: setGptDetail,
    },
    t,
    prompts: {
      list: prompts,
      hook: promptHook,
      input: inputPrompt,
    },
  };
};
