/* eslint-disable no-irregular-whitespace */
export type MarkDown = {
  version: string;
  content: string;
};

export const ReleaseNote: MarkDown[] = [
  {
    version: 'v2.3.2_ja',
    content: `
# Toyotsu GChat リリースノート v2.3.2
**リリースノート作成日** : 2024年12月18日
**リリース日** : 2024年12月19日
## 概要
本リリースでは、ホーム画面のプロンプト入力欄を改良しました。
## 修正内容
### ホーム画面
- チャット画面と同様のプロンプト入力欄に変更し、ホーム画面から様々なチャット機能を利用できるようになりました。
`,
  },
  {
    version: 'v2.3.2_en',
    content: `
# Toyotsu GChat Release Notes v2.3.2
**Release Notes Date**: December 18, 2024
**Release Date**: December 19, 2024
## Overview
In this release, we have improved the prompt input field on the home screen.
## Fixes
### Home screen
- Changed the prompt input field to be similar to the one in the chat screen, allowing access to various chat features directly from the home screen.
`,
  },
  {
    version: 'v2.3.1_ja',
    content: `
# Toyotsu GChat リリースノート v2.3.1
**リリースノート作成日** : 2024年12月11日
**リリース日** : 2024年12月13日
## 概要
本リリースでは、web検索のUIや精度を改善しました。
＊質問に対して、AIがネット上の最新情報を検索して、要約してくれる機能のこと
## 修正内容
### チャット機能
- web検索アイコンをメッセージ送信ボタン横に配置しました。
- web検索精度を改善しました。
`,
  },
  {
    version: 'v2.3.1_en',
    content: `
# Toyotsu GChat Release Notes v2.3.1
**Release Notes Date**: December 11, 2024
**Release Date**: December 13, 2024
## Overview
In this release, we have improved the UI and accuracy of web search.
＊This refers to the feature where the AI searches for the latest information online and summarizes it in response to questions.
## Fixes
### Chat Function
- The web search icon has been placed next to the message send button.
- The accuracy of web search has been improved.
`,
  },
  {
    version: 'v2.3.0_ja',
    content: `
# Toyotsu GChat リリースノート v2.3.0
**リリースノート作成日** : 2024年10月30日
**リリース日** : 2024年10月31日
## 概要
本リリースでは、個人用文書検索機能の追加を行いました。
＊日本国内の会社のみ
## 追加機能
### 文書検索機能
- 文書を登録することができるようになりました。
- 自身が登録した文書を参照した回答を生成できるようになりました。
`,
  },
  {
    version: 'v2.3.0_en',
    content: `
# Toyotsu GChat Release Notes v2.3.0
**Release Notes Date**: October 30, 2024
**Release Date**: October 31, 2024
## Overview
In this release, we have added a document search feature.
＊Companies in Japan only
## New Features
### Document Search Function
- Documents can now be registered.
- It is now possible to generate responses that refer to documents registered by the applicant.
`,
  },
  {
    version: 'v2.2.3_ja',
    content: `
# Toyotsu GChat リリースノート v2.2.3
**リリースノート作成日** : 2024年9月10日
**リリース日** : 2024年9月30日

## 概要
本リリースでは、チャット画面に画像添付機能の追加を行いました。

## 修正内容
### チャット機能
- チャット画面で画像を添付できるようになりました。
- 添付した画像に基づいて、関連する回答を生成できるようになりました。
`,
  },
  {
    version: 'v2.2.3_en',
    content: `
# Toyotsu GChat Release Notes v2.2.3
**Release Notes Date**: September 10, 2024
**Release Date**: September 30, 2024

## Overview
In this release, we have added an image attachment feature to the chat screen.

## Fixes
### Chat Function
- Users can now attach images in the chat screen.
- Based on the attached image, relevant responses can now be generated.
`,
  },
  {
    version: 'v2.2.2_ja',
    content: `
# Toyotsu GChat リリースノート v2.2.2
**リリースノート作成日** : 2024年8月29日
**リリース日** : 2024年8月30日

## 概要
本リリースでは、GPT-4の新モデルの提供や議事録の文字起こし機能の改善を行いました。

## 修正内容
### チャット機能
- GPT-4の新モデル（GPT4o-mini）を利用できるようになりました。
※ 上記に伴いGPT-3.5 Turboを廃止しました。

### 議事録作成機能
- Zoomで3時間程度の打ち合わせの文字起こしに対応しました。
- 議事録作成でGPT-4の新モデル（GPT4o-mini）を利用できるようになりました。
※ 上記に伴いGPT-3.5 Turboを廃止しました。
`,
  },
  {
    version: 'v2.2.2_en',
    content: `
# Toyotsu GChat Release Notes v2.2.2
**Release Notes Date**: August 29, 2024
**Release Date**: August 30, 2024

## Overview
In this release, we have a new GPT-4 model and improved transcription of minutes.

## Fixes
### Chat Function
- A new model of GPT-4 (GPT4o-mini) is now available.
※ GPT-3.5 Turbo has been discontinued due to the above.

### Minute Creation Function
- Transcription of a 3-hour meeting is now supported by Zoom.
- A new model of GPT-4 (GPT4o-mini) is now available for minutes creation.
※ GPT-3.5 Turbo has been discontinued due to the above.
`,
  },

  {
    version: 'v2.2.1_ja',
    content: `
# Toyotsu GChat リリースノート v2.2.1
**リリースノート作成日** : 2024年7月18日
**リリース日** : 2024年7月23日

## 概要
本リリースでは、UIの改善や多言語対応を行いました。

## 修正内容
### チャット機能
- 「新しい会話をスタートする」ボタンの文言を「話題を変えてチャットを開始する」に変更しました。
※ チャットの話題が変わる場合はこちらを利用してください。チャットの精度が上がります。
- 音声再生機能にチェコ語、ポーランド語、トルコ語を追加しました。

### 議事録作成機能
- 入力言語にチェコ語、ポーランド語、トルコ語を追加しました。

`,
  },
  {
    version: 'v2.2.1_en',
    content: `
# Toyotsu GChat Release Notes v2.2.1
**Release Notes Date**: July 18, 2024
**Release Date**: July 23, 2024

## Overview
In this release, we have improved UI improved and multilingual support.

## Fixes
### Chat Function
- Changed the wording of the "Start a New Conversation" button to "Change the topic and start chatting".
※ Please use this when changing the topic of the chat. It will improve the accuracy of the chat.
- Added Czech, Polish, and Turkish to the voice playback feature.

### Minute Creation Function
- Added Czech, Polish, and Turkish to the input audio language.
`,
  },
  {
    version: 'v2.2.0_ja',
    content: `
# Toyotsu GChat リリースノート v2.2.0
**リリースノート作成日** : 2024年6月11日
**リリース日** : 2024年6月26日

## 概要
本リリースでは、UIの改善やGPT-4の新モデルの提供を行いました。

## 追加機能
### ホーム画面
- GChatの各機能への遷移元となるホーム画面を追加しました。

### チャット機能
- GPT-4の新モデル（GPT4o）を利用できる様になりました。
- Web Searchの選択をオプション内に移動しました。
- 使用頻度の高いプロンプトを表示するようにしました。

### 議事録作成機能
- GPT-4の新モデル（GPT4o）を利用できる様になりました。
- 文字起こし、議事録作成に画面を分割しました。
`,
  },
  {
    version: 'v2.2.0_en',
    content: `# Toyotsu GChat Release Notes v2.2.0
**Release Notes Date**: June 11, 2024
**Release Date**: June 26, 2024

## Overview
In this release, we have improved the UI and provided a new GPT-4 model.

## Fixes
### Home screen
- We have added a home screen that will allow you to access each GChat function.

### Chat Function
- You can now use a new model of GPT-4 (GPT4o).
- Web Search selection has been moved to the options.
- Frequently used prompts are now displayed.

### Minute Creation Function
- You can now use a new model of GPT-4 (GPT4o).
- The screen is now split for transcription and minutes creation.
`,
  },
  {
    version: 'v2.1.0_ja',
    content: `
# Toyotsu GChat リリースノート v2.1.0
**リリースノート作成日** : 2024年4月15日
**リリース日** : 2024年4月15日

## 概要
本リリースでは、日本国外の利用を目的とした利用規約同意管理機能の追加を行いました。

## 追加機能
### 利用規約同意管理機能
- 「プライバシーポリシー」「GDPR」「CCPA」の3点について、未同意の場合はログイン時に同意を求めるポップアップを表示します。
- それぞれの内容に更新があった場合は、再度ポップアップを表示します。
- 「お知らせ」タブからそれぞれの最新内容を確認可能です。
`,
  },
  {
    version: 'v2.1.0_en',
    content: `# Toyotsu GChat Release Notes v2.1.0
**Release Notes Date**: April 15, 2024
**Release Date**: April 15, 2024

## Overview
In this release, we have added a terms of use agreement management feature for use outside of Japan.

## New Features
### Terms of Use Agreement Management Function
- If you have not agreed to the "Privacy Policy", "GDPR", and "CCPA", a pop-up will be displayed at login asking for your agreement.
- If there are updates to any of these contents, the pop-up will be displayed again.
- You can check the latest content of each from the "Announcements" tab.
`,
  },
  {
    version: 'v2.0.6_ja',
    content: `
# Toyotsu GChat リリースノート v2.0.6
**リリースノート作成日** : 2024年2月5日
**リリース日** : 2024年2月5日

## 概要
一部不具合を修正しました。

## 修正内容
### ログイン
- iOS16にてログインができない問題を修正しました。
`,
  },
  {
    version: 'v2.0.6_en',
    content: `# Toyotsu GChat Release Notes v2.0.6
**Release Notes Date**: February 5, 2024
**Release Date**: February 5, 2024

## Overview
Fixed a bug.

## Fix
### Login
- Fixed an issue where login was not possible on iOS 16.
`,
  },
  {
    version: 'v2.0.5_ja',
    content: `
# Toyotsu GChat リリースノート v2.0.5
**リリースノート作成日** : 2024年1月31日
**リリース日** : 2024年1月31日

## 概要
本リリースでは、チャットの返信の読み上げ機能の追加やGPT-3.5、4の新モデルの提供を行いました。

## 追加機能
### チャット機能
- チャットの返信の読み上げができる様になりました。
- GPT3.5-Turbo、GPT4-Turboの新モデルを利用できる様になりました。
`,
  },
  {
    version: 'v2.0.5_en',
    content: `# Toyotsu GChat Release Notes v2.0.5
**Release Notes Date**: January 31, 2024
**Release Date**: January 31, 2024

## Overview
In this release, we have added a read-aloud feature for chat replies and provided new models of GPT 3.5 and 4.

## Fixes
### Chat Function
- We have now enabled the read-aloud feature for chat replies.
- You can now use new model of GPT3.5-Turbo and GPT4-Turbo.
`,
  },
  {
    version: 'v2.0.4_ja',
    content: `
# Toyotsu GChat リリースノート v2.0.4
**リリースノート作成日** : 2023年12月14日
**リリース日** : 2023年12月14日

## 概要
本リリースは、機能改善のための小規模な修正のみ行いました。


## 修正内容
### 議事録
- 一部エラーメッセージを修正しました。
`,
  },
  {
    version: 'v2.0.4_en',
    content: `# Toyotsu GChat Release Notes v2.0.4
**Release Notes Date**: December 14, 2023
**Release Date**: December 14, 2023

## Overview
Only minor fixes have been made in this release to improve functionality.

## Fixes
### Minute Creation Function
- Fixed some error messages.
`,
  },
  {
    version: 'v2.0.3_ja',
    content: `
# Toyotsu GChat リリースノート v2.0.3
**リリースノート作成日** : 2023年12月5日
**リリース日** : 2023年12月5日

## 概要
本リリースは、機能改善のための小規模な修正のみ行いました。


## 修正内容
### 議事録
- 議事録作成で言語を指定できるようにしました。
- 一部エラーメッセージの修正しました。
`,
  },
  {
    version: 'v2.0.3_en',
    content: `# Toyotsu GChat Release Notes v2.0.3
**Release Notes Date**: December 5, 2023
**Release Date**: December 5, 2023

## Overview
Only minor fixes have been made in this release to improve functionality.

## Fixes
### Minute Creation Function
- You can now specify a language in the minutes.
- Fixed some error messages.
`,
  },
  {
    version: 'v2.0.2_ja',
    content: `
# Toyotsu GChat リリースノート v2.0.2
**リリースノート作成日** : 2023年11月20日
**リリース日** : 2023年11月20日

## 概要
本リリースは、機能改善のための小規模な修正のみ行いました。


## 修正内容
### チャット
- Web検索結果のURLを開いた時に別タブで開くようにしました。

### 議事録
- 議事録の結果をストリーミングで表示するようにしました。
- 議事録の表示形式にプレビューモードを追加しました。
- 一部エラーメッセージの修正しました。
- メニューを切り替えても文字起こし、議事録の結果が残るよう修正しました。
`,
  },
  {
    version: 'v2.0.2_en',
    content: `# Toyotsu GChat Release Notes v2.0.2
**Release Notes Date**: November 20, 2023
**Release Date**: November 20, 2023

## Overview
Only minor fixes have been made in this release to improve functionality.

## Fixes
### Chat Function
- We have made it so that when you open a URL from web search results, it will now open in a separate tab.

### Minute Creation Function
- We have implemented the ability to display the results of the minutes in streaming.
- We have added a preview mode to the display format of the minutes.
- We have implemented the ability to save the token usage in the minutes.
- We have made some corrections to error messages.
- We have made modifications so that the transcription and results of the minutes remain even when switching menus.
`,
  },
  {
    version: 'v2.0.1_ja',
    content: `
# Toyotsu GChat リリースノート v2.0.1
**リリースノート作成日** : 2023年11月15日
**リリース日** : 2023年11月15日

## 概要
本リリースは、機能改善のための小規模な修正のみ行いました。

## 修正内容
### 議事録作成機能の選択部品のUIを変更
議事録作成機能の際の選択部品をチェックボックスからラジオボタンへ変更しました。
`,
  },
  {
    version: 'v2.0.1_en',
    content: `
# Toyotsu GChat Release Notes v2.0.1
**Release Notes Date**: November 15, 2023
**Release Date**: November 15, 2023

## Overview
Only minor fixes have been made in this release to improve functionality.

## Fixes
### Change the UI of the selected parts of the minutes preparation function
We have changed the selected parts for the minutes preparation function from a check box to a radio button.
`,
  },
  {
    version: 'v2.0.0_ja',
    content: `
# Toyotsu GChat リリースノート v2.0.0
**リリースノート作成日** : 2023年10月30日
**リリース日** : 2023年11月1日

## 概要
本リリースでは、これまでのChat機能にGPT4の利用や編集機能をはじめとする多くの機能の追加を行いました。
また、ZoomやTeamsのログがデータから議事録を作成することができる機能、プロンプトのサンプルを検索・利用できる機能追加などを行いました。


## 追加機能
### チャット機能
- プロンプト編集機能
  - これまでに使ったプロンプトを修正し、再度送信できるようになりました。
- GPT4を利用できる様に
  - 利用モデルを選択できる様にし、GPT4が選べる様になりました。
- 音声入力機能を追加
  - Chat機能で音声入力が可能になりました。
- Web検索機能を追加
  - Web検索機能を選択することにより、最新の情報を取得できる様になりました。

### 議事録作成機能 <span style="color:red; font-size:13px; vertical-align:top">New!</span>
- 概要
  - 本機能は、録音または録画したオンライン会議の音声データをテキスト化し、議事録を作成する機能です。
  それ以外にも、画面上の録音ボタンを押すことで、その場で録音した音声をもとに議事録を作成することもできます。
  - GChatの議事録作成よりも高機能なサービスをご要望の場合は、以下の掲示板をご参照下さい

  > **AIGIJIROKUのご紹介**
  > https://toyotsu.service-now.com/kb_view.do?sysparm_article=KB0019390

### プロンプトサンプル機能 <span style="color:red; font-size:13px; vertical-align:top">New!</span>
- 概要
  - 新たにプロンプトのサンプルの一覧を表示、検索できる機能を追加しました。
  プロンプトごとに利用回数が表示されるようになっており、利用頻度の高いプロンプトがわかる様になります。

### お知らせ機能 <span style="color:red; font-size:13px; vertical-align:top">New!</span>
- リリースされた新機能やお知らせの内容を掲載できる機能を追加しました。


## 不具合修正
- チャット画面で時々左のメニューが表示されない問題の修正

`,
  },
  {
    version: 'v2.0.0_en',
    content: `# Toyotsu GChat Release Notes v2.0.0
**Release Notes Date**: October 30, 2023
**Release Date**: November 1, 2023

## Overview
In this release, we've added numerous features to the Chat function, including the use of GPT-4 and editing capabilities.
We've also added features such as the ability to generate minutes from the recording data of Zoom and Teams, as well as searching and using sample prompts.

## New Features
### Chat Function
- **Prompt Editing Function**
  - You can now modify prompts you've used before and resend them.
- **Ability to use GPT-4**
  - You can now choose which model to use and select GPT-4.
- **Added Voice Input**
  - Voice input is now available in the Chat function.
- **Added Web Search**
  - With the web search feature, you can now retrieve the latest information.

### Minute Creation Function <span style="color:red; font-size:13px; vertical-align:top">New!</span>
- **Overview**
  - This function converts audio data from recorded online meetings into text and creates minutes. In addition, by pressing the recording button on the screen, you can create minutes based on the audio recorded on the spot.
  - If you need a more advanced service than GChat's minute creation, please refer to the URL below:
    **[Introduction to AIGIJIROKU](https://toyotsu.service-now.com/kb_view.do?sysparm_article=KB0019390)**

### Prompt Sample Function <span style="color:red; font-size:13px; vertical-align:top">New!</span>
- **Overview**
  - We've added a new feature that displays and searches a list of prompt samples. The number of uses for each prompt is displayed, so you can see which prompts are frequently used.

### Notification Function <span style="color:red; font-size:13px; vertical-align:top">New!</span>
- We've added a feature that allows us to post new features and notifications.

## Bug Fixes
- Fixed an issue where the left menu sometimes doesn't display on the chat screen.
`,
  },
];
