import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Page } from 'src/components-minimals/Page';
import { useHomePageModel } from './useHomePageModel';
import { ChatLogo } from 'src/components/ChatIcon';
import { MenuCard } from 'src/components/MenuCard';
import { InputMessage } from 'src/components/InputMessage';
import { LayoutToggleButtons } from 'src/components/LayoutToggleButtons';
const HomePage = (): React.ReactElement => {
  const pm = useHomePageModel();

  return (
    <Page title="Toyotsu GChat">
      <Container maxWidth={false} sx={{ padding: '0px' }}>
        <>
          <Grid item xs={12} sm={12} md={12}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack direction={'row'} spacing={2}>
                <Grid pt={pm.isMobile ? 0 : 1}>
                  <ChatLogo size={45} themeMode={pm.themeMode} />
                </Grid>
                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                  <Typography
                    className="marsFont"
                    sx={{
                      paddingBottom: '0px',
                      fontSize: { xs: '30px', sm: '35px' },
                      fontWeight: '300',
                    }}>
                    Toyotsu GChat
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid container xs={12} direction="row" justifyContent={'center'} sx={{ paddingTop: { xs: '10px', sm: '10px', md: '10px', lg: '20px' } }}>
            <LayoutToggleButtons options={pm.selection.options} onChange={pm.selection.onChangeOption} value={pm.selection.value} />
          </Grid>
          <Grid id="asdasd" container sx={{ position: 'relative' }} mb={1}>
            <InputMessage
              position={'static'}
              isChat={false}
              maxTokenLength={pm.maxTokenLength}
              progress={pm.progress}
              tokenLength={pm.tokenLength}
              messageToken={pm.messageToken}
              themeMode={pm.themeMode}
              anchorElement={pm.anchorEl}
              itemList={pm.itemList}
              newButtonLabel={pm.newBtnLabel}
              placeholder={pm.placeholder}
              isLoading={pm.isLoading}
              messageInputBoxValue={pm.inputMessage}
              inputElement={pm.inputElement}
              recordingStatus={pm.recordingStatus}
              newBtnOnClick={pm.newBtnOnClick}
              onChange={pm.handleInputChange}
              onSubmit={pm.handleSendMessageHomeButton}
              menuOnClick={pm.meatballsClick}
              menuOnClose={pm.meatballsClose}
              onRecordingStartButtonClick={pm.onRecordingStartButtonClick}
              onRecordingStopButtonClick={pm.onRecordingStopButtonClick}
              anchorOrigin={pm.anchorOrigin}
              transformOrigin={pm.transformOrigin}
              isWebSearch={pm.isWebSearch}
              chatModel={pm.chatModel}
              plugin={pm.plugin}
              chatMain={true}
              handlePaste={pm.handlePaste}
              images={pm.images}
              imgClear={pm.imgClearOne}
              inputFile={pm.inputFile}
              isMobile={pm.isMobile}
              handleSwitchWebSearch={pm.handleSwitchWebSearch}
            />
          </Grid>
          <Container maxWidth="md">
            <Grid container spacing={1} justifyContent={'flex-start'}>
              {pm.menuData.map((item) => (
                <MenuCard
                  title={pm.t(`home.title.${item.item}`)}
                  description={pm.t(`home.description.${item.item}`)}
                  img={item.item}
                  onClick={() => {
                    if (item.item === 'chat') pm.handleHomeToChat(true);
                    pm.navigate(item.path);
                  }}
                  guideline={pm.t(`home.tooltip.${item.item}`)}
                  isMobile={pm.isMobile}
                />
              ))}
            </Grid>
          </Container>
        </>
      </Container>
    </Page>
  );
};
export default HomePage;
