import { createRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useChatMessageContext, useDispatchChatMessageContext } from 'src/contexts/ChatMessageContext';
import snackbar from 'src/components-mars/Snackbar';
import { mainChatAPI } from 'src/apis/mainChatAPI';
import {
  ARROWED_TO_ACCESS_DOCUMENT_PERSONAL,
  ARROWED_TO_ACCESS_DOCUMENT_SEARCH,
  ARROWED_TO_ACCESS_DOCUMENT_UPLOAD,
  CHAT_TAKE_COUNT,
} from 'src/constants';
import useSettings from 'src/hooks/useSettings';
import useMessage from 'src/hooks/useMessage';
import useRecorder from 'src/hooks/useRecorder2';
import { useTranscribe } from 'src/hooks/useTranscribe';
import { azureWhisper } from 'src/models/transcribe-service';
import { RootState, dispatch, useSelector } from 'src/redux/store';
import { setSideBarDefault } from 'src/redux/slices/sidebarSlice';
import { PATH_CHAT, PATH_DOC_SEARCH, PATH_PROMPT, RELEASE_NOTES_V1_X, PATH_OTHERS, PATH_MINUTES } from 'src/routes/paths';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useSelection from 'src/hooks/useSelection';
import { useApiError } from 'src/hooks/useApiError';
import { isNotReleaseFunction } from 'src/utils/envChecker';
import { roleCheck } from 'src/guards/RoleBasedGuard';
import { ChatCompletionContentPart } from 'openai/resources';
import { OriginImageList } from 'src/contexts/ChatMessageReducer';

export const useHomePageModel = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { themeMode } = useSettings();
  const theme = useTheme();
  const { onSnackbarClose, closeSnackbar } = snackbar();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const apiError = useApiError(t);
  const isDisplayDocSearchMenu = roleCheck([
    ...ARROWED_TO_ACCESS_DOCUMENT_PERSONAL,
    ...ARROWED_TO_ACCESS_DOCUMENT_SEARCH,
    ...ARROWED_TO_ACCESS_DOCUMENT_UPLOAD,
  ]);
  // context
  const {
    abortCntl,
    chatEnd,
    chatLog,
    chatMessages,
    inputMessage,
    images,
    recordingStatus,
    selectId,
    skipCount,
    useChatType,
    isWebSearch,
    handleChatEnd,
    handleChatLog,
    handlingErrorGetPreMessages,
    handleAbortCntl,
    handleInactivity,
    handleSwitchWebSearch,
    handleInputMessage,
    handleAttachedImages,
    handleIsNewChat,
    handleRecordingStatus,
    handleSelectId,
    handleSkipCount,
    handleUseChatType,
    homeMessage,
    handleSwitchHomeMessage,
    handleHomeToChat,
    topicFlag,
    handleTopicFlag,
  } = useChatMessageContext();
  const dispatchMessageChat = useDispatchChatMessageContext();

  // dispatch action
  // delete
  const dispatchActionDelete = () =>
    dispatchMessageChat({
      type: 'message/delete',
      payload: { role: 'user', content: '' },
    });

  // update
  const dispatchActionUpdate = (message: string) =>
    dispatchMessageChat({
      type: 'message/update',
      payload: {
        role: 'assistant',
        content: message,
      },
    });
  // add user
  const dispatchActionAddUser = (message: string | ChatCompletionContentPart[], originImg?: OriginImageList[]) =>
    dispatchMessageChat({
      type: 'message/add',
      payload: {
        role: 'user',
        content: message,
        originImg: originImg,
      },
    });
  // add assist
  const dispatchActionAddAssist = () =>
    dispatchMessageChat({
      type: 'message/add',
      payload: {
        role: 'assistant',
        content: ' ',
      },
    });
  // reset
  const dispatchActionReset = () =>
    dispatchMessageChat({
      type: 'message/addReset',
      payload: {
        role: 'user',
        content: '',
      },
    });
  // add edit
  const dispatchActionEdit = (message: string | ChatCompletionContentPart[], index: number) =>
    dispatchMessageChat({
      type: 'message/edit',
      payload: {
        role: 'user',
        content: message,
      },
      index: index,
    });

  // const [composing, setComposition] = React.useState(false);
  // const startComposition = React.useCallback(() => setComposition(true), []);
  // const endComposition = React.useCallback(() => setComposition(false), []);

  // store
  const isLoading = useSelector((state: RootState) => state.chatGptState).isLoading;

  const existChatMessage = chatMessages && chatMessages.length !== 0;
  const inputElement = createRef<HTMLInputElement>();

  // 処理待ち状態
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  // 翻訳結果テキスト
  const [transcript, setTranscript] = useState<string>('');

  const tokenOverMessage = () => {
    closeSnackbar();
    onSnackbarClose({
      color: 'error',
      msg: t('chat.message.error.contextLengthExceeded'),
      isPersist: true,
    });
  };

  const errorMessage = (e: any) => {
    console.log(e.message);
    if (e.message === 'context_length_exceeded') {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: t('chat.message.error.contextLengthExceeded'),
        isPersist: true,
      });
    } else if (e.message === 'error_in_bing') {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: t('common.message.error.bingError'),
        isPersist: true,
      });
    } else if (e.message === 'content_filter') {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: t('common.message.error.contentFilter'),
        isPersist: true,
      });
    } else if (e.message === 'more_than_one_url') {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: t('chat.message.error.moreThanOneUrl'),
        isPersist: true,
      });
    } else if (e.message === 'web_token_over') {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: t('chat.message.error.webTokenOver'),
        isPersist: true,
      });
    } else if (e.message.includes('Timeout')) {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: t('common.message.error.timeout'),
        isPersist: true,
      });
    } else if (!(e instanceof DOMException && e.name === 'AbortError')) {
      closeSnackbar();
      onSnackbarClose({
        color: 'error',
        msg: `${t('common.message.error.sendGPTMessage')} ${e}`,
        isPersist: true,
      });
    }
  };

  const { start, stop } = useRecorder();
  const { transcribe } = useTranscribe();

  const startRecording = useCallback(async () => {
    await start();
  }, []);

  const handleSendMessageHomeButton = () => {
    handleHomeToChat(true);
    handleSwitchHomeMessage(true);
    navigate(PATH_CHAT.main);
  };

  // const onKeyDownHandler = React.useCallback(
  //   (event: React.KeyboardEvent<HTMLInputElement>) => {
  //     if (event.key === 'Enter' && event.shiftKey) {
  //       // Handle Shift + Enter key press event
  //     } else if (event.key === 'Enter') {
  //       // 何も入力されていない場合、またはメッセージ受信中の場合は送信できない
  //       if (chatContext.inputMessage.length === 0) {
  //         // なにも入力されていない状態でEnterを押した場合は何もしない
  //         event.preventDefault();
  //       } else if (!composing) {
  //         event.preventDefault();
  //         handleSendMessageButton();
  //       }
  //     }
  //   },
  //   [chatContext.inputMessage, composing],
  // );

  /**
   * 文字起こしする処理
   * @param file オーディオファイル
   */
  const onTranscribe = useCallback(
    async (file: File) => {
      setIsWaitingForResponse(true);

      try {
        const text = await transcribe(file, azureWhisper.service, 600, null);
        setTranscript(() => text);
      } catch (error) {
        const message = apiError.getErrorMessage(error);
        onSnackbarClose({
          color: 'error',
          msg: `${t('common.message.error.transcribe')} ${message}`,
          isPersist: true,
        });
      } finally {
        setIsWaitingForResponse(false);
      }
    },
    [useChatType, transcript, isWaitingForResponse],
  );

  const stopRecording = useCallback(async () => {
    try {
      const file = await stop();
      if (file !== null) {
        onTranscribe(file);
      }
    } catch (error) {
      console.error(error);
    }
  }, [onTranscribe, stop]);

  const {
    progress,
    tokenLength,
    messageToken,
    newBtnLabel,
    newBtnOnClick,
    meatballsClick,
    anchorEl,
    meatballsClose,
    itemList,
    onRecordingStartButtonClick,
    onRecordingStopButtonClick,
    handleInputChange,
    // onKeyDownHandler,
    // startComposition,
    // endComposition,
    placeholder,
    anchorOriginTR,
    transformOriginBR,
    plugin,
    handlePaste,
    // images,
    imgClearOne,
    inputFile,
  } = useMessage({
    updated: existChatMessage && chatEnd,
    handleInputMessage,
    handleAttachedImages,
    selectId,
    handleUseChatType,
    handleIsNewChat,
    handleSelectId,
    abortCntl,
    dispatchActionDelete,
    startRecording,
    stopRecording,
    handleRecordingStatus,
    inputMessage,
    images,
    isLoading,
    handleChatLog,
    dispatchActionUpdate,
    dispatchActionAddUser,
    dispatchActionAddAssist,
    dispatchActionReset,
    chatLog,
    useChatType,
    tokenOverMessage,
    handleChatEnd,
    chatMessages,
    handleAbortCntl,
    handleSkipCount,
    skipCount,
    errorMessage,
    dispatchActionEdit,
    handlingErrorGetPreMessages,
    isWebSearch,
    homeMessage,
    handleSwitchHomeMessage,
    handleSwitchWebSearch,
    topicFlag,
    handleTopicFlag,
  });

  const menuData = isNotReleaseFunction()
    ? isDisplayDocSearchMenu
      ? [
          { item: 'chat', path: PATH_CHAT.main },
          { item: 'document', path: PATH_DOC_SEARCH.root },
          { item: 'minutes', path: PATH_MINUTES.transcribing },
          { item: 'prompt', path: PATH_PROMPT.page },
          { item: 'announce', path: RELEASE_NOTES_V1_X[0].path },
          { item: 'others', path: PATH_OTHERS.snowflake },
        ]
      : [
          { item: 'chat', path: PATH_CHAT.main },
          { item: 'minutes', path: PATH_MINUTES.transcribing },
          { item: 'prompt', path: PATH_PROMPT.page },
          { item: 'announce', path: RELEASE_NOTES_V1_X[0].path },
          { item: 'others', path: PATH_OTHERS.snowflake },
        ]
    : isDisplayDocSearchMenu
    ? [
        { item: 'chat', path: PATH_CHAT.main },
        { item: 'document', path: PATH_DOC_SEARCH.root },
        { item: 'minutes', path: PATH_MINUTES.transcribing },
        { item: 'prompt', path: PATH_PROMPT.page },
        { item: 'announce', path: RELEASE_NOTES_V1_X[0].path },
      ]
    : [
        { item: 'chat', path: PATH_CHAT.main },
        { item: 'minutes', path: PATH_MINUTES.transcribing },
        { item: 'prompt', path: PATH_PROMPT.page },
        { item: 'announce', path: RELEASE_NOTES_V1_X[0].path },
      ];

  const selection = useSelection({
    useChatType: useChatType,
    handleUseChatType: handleUseChatType,
  });

  useEffect(() => {
    dispatch(setSideBarDefault());
    handleInactivity();
    mainChatAPI
      .getChatLogs(CHAT_TAKE_COUNT, skipCount)
      .then((res) => {
        if (res.data.length > 0) {
          setTimeout(() => {
            handleChatLog([...chatLog, ...res.data]);
            handleSkipCount(skipCount + CHAT_TAKE_COUNT);
          }, 10);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // focusing record
  useEffect(() => {
    if (inputMessage.length === 0 || transcript.length !== 0) {
      console.log('transcript⭐️', transcript);
      handleInputMessage(transcript);
      setTranscript('');
    }
    const node = inputElement.current;
    if (node) {
      node.focus();
    }
  }, [transcript]);

  // update recording status
  useEffect(() => {
    if (!isWaitingForResponse) {
      handleRecordingStatus('STOPPING');
    }
  }, [isWaitingForResponse]);

  return {
    isMobile,
    isLoading,
    progress,
    tokenLength,
    messageToken,
    maxTokenLength: useChatType.maxTokenLength,
    chatModel: useChatType.label,
    newBtnOnClick,
    newBtnLabel,
    themeMode,
    meatballsClick,
    anchorEl,
    meatballsClose,
    itemList,
    anchorOrigin: anchorOriginTR,
    transformOrigin: transformOriginBR,
    recordingStatus,
    inputElement,
    onRecordingStartButtonClick,
    onRecordingStopButtonClick,
    plugin,
    isWebSearch,
    handleSwitchWebSearch,
    t,
    navigate,
    menuData,
    handleHomeToChat,
    handleInputChange,
    // onKeyDownHandler,
    // startComposition,
    // endComposition,
    placeholder,
    inputMessage,
    handlePaste,
    images,
    imgClearOne,
    inputFile,
    handleSendMessageHomeButton,
    selection,
  };
};
