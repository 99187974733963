import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatLog } from 'src/@types/chat';
import { CustomMenuItemProps } from 'src/components/CustomMenuItem';
import { ChatCompletionRequestMessageWithId, OriginImageList } from 'src/contexts/ChatMessageReducer';
import { RecordingStatus } from 'src/contexts/type/ChatMessageContextType';
import { OptionChatType, chatModels } from 'src/models/chat-gpt';
import { startLoading, stopLoading } from 'src/redux/slices/chatGptSlice';
import { dispatch } from 'src/redux/store';
import { submitMessageToOpenApiInSync } from 'src/shared/api/messages/StreamCompletion';
import { inputTokenLength } from 'src/utils/calculateToken';
import { getTokenLength } from 'src/utils/getTokenLength';
import snackbar from 'src/components-mars/Snackbar';
import { useAnchor } from './useAnchor';
import { useTTSSettings } from './useTTSSettings';
import { ChatCompletionContentPart, ChatCompletionMessageParam } from 'openai/resources';
// import { imageStart } from 'src/constants';
import { apiGateway, prefixApiGateway } from 'src/config';
import { mainChatAPI } from 'src/apis/mainChatAPI';
import _ from 'lodash';
import { ImageNum, imageOriginAddress } from 'src/constants';
import { useSnackbar } from 'src/hooks/useSnackbar';

interface UseMessageProps {
  handleInputMessage: (data: string) => void;
  handleAttachedImages: (data: string[]) => void;
  updated: boolean;
  selectId: string;
  handleIsNewChat: (data: boolean) => void;
  handleUseChatType: (data: OptionChatType) => void;
  dispatchActionDelete: () => void;
  abortCntl: AbortController | undefined;
  handleSelectId: (data: string) => void;
  startRecording: () => Promise<void>;
  stopRecording: () => Promise<void>;
  handleRecordingStatus: (recordingStatus: RecordingStatus) => void;
  inputMessage: string;
  images: string[];
  isLoading: boolean;
  dispatchActionUpdate: (message: string) => void;
  dispatchActionAddUser: (message: string | ChatCompletionContentPart[], originImg?: OriginImageList[]) => void;
  dispatchActionAddAssist: () => void;
  dispatchActionReset: () => void;
  chatLog: ChatLog[];
  handleChatLog: (datas: ChatLog[]) => void;
  useChatType: OptionChatType;
  tokenOverMessage: () => void;
  handleChatEnd: (data: boolean) => void;
  chatMessages: ChatCompletionRequestMessageWithId[];
  handleAbortCntl: (data: AbortController | undefined) => void;
  handleSkipCount: (data: number) => void;
  skipCount: number;
  errorMessage: (e: any) => void;
  dispatchActionEdit: (message: string | ChatCompletionContentPart[], index: number) => void;
  handlingErrorGetPreMessages: () => void;
  isWebSearch: boolean;
  homeMessage: boolean;
  handleSwitchHomeMessage: (data: boolean) => void;
  handleSwitchWebSearch: (value: boolean) => void;
  topicFlag: boolean;
  handleTopicFlag: (flag: boolean) => void;
}
const useMessage = ({
  handleInputMessage,
  handleAttachedImages,
  updated,
  selectId,
  handleIsNewChat,
  handleUseChatType,
  dispatchActionDelete,
  abortCntl,
  handleSelectId,
  startRecording,
  handleRecordingStatus,
  stopRecording,
  inputMessage,
  images,
  isLoading,
  dispatchActionUpdate,
  dispatchActionAddUser,
  dispatchActionAddAssist,
  dispatchActionReset,
  chatLog,
  handleChatLog,
  useChatType,
  tokenOverMessage,
  handleChatEnd,
  chatMessages,
  handleAbortCntl,
  handleSkipCount,
  skipCount,
  errorMessage,
  dispatchActionEdit,
  handlingErrorGetPreMessages,
  isWebSearch,
  homeMessage,
  handleSwitchHomeMessage,
  handleSwitchWebSearch,
  topicFlag,
  handleTopicFlag,
}: UseMessageProps) => {
  const { t, i18n } = useTranslation();

  // token
  const [tokenLength, setTokenLength] = useState(0);
  const [messageToken, setMessageToken] = useState(0);
  const { closeSnackbar } = snackbar();
  const snackbarHooks = useSnackbar();
  useEffect(() => {
    if (updated) {
      getTokenLength(selectId, setTokenLength);
    }
  }, [updated]);

  useEffect(() => {
    setMessageToken(0);
    if (!selectId) setTokenLength(0);
    getTokenLength(selectId, setTokenLength);
  }, [selectId]);

  const handleMessageToken = useCallback((data: number) => {
    setMessageToken(data);
  }, []);

  let progress = Math.ceil(((tokenLength + messageToken) / useChatType.maxTokenLength) * 100);
  // progressが100以上だったら100にする
  if (progress > 100) {
    progress = 100;
  }

  // new chat
  const newBtnLabel = t('chat.button.newChat');

  const newBtnOnClick = useCallback(() => {
    handleIsNewChat(true);
    handleUseChatType(chatModels[0]);
    dispatchActionDelete();
    if (abortCntl) {
      abortCntl.abort();
    }
    handleSelectId('');
    if (Boolean(inputMessage)) handleTopicFlag(true);
  }, [abortCntl, inputMessage]);

  // ---------------- meatballs menu
  const { anchorEl, openAnchor, closeAnchor, anchorOriginTR, transformOriginBR } = useAnchor();

  const deleteMessageHandler = useCallback(() => {
    handleInputMessage('');
    handleAttachedImages([]);
    closeAnchor();
  }, []);

  const pluginHandler = useCallback(() => {
    handleSwitchWebSearch(!isWebSearch);
  }, [isWebSearch]);

  const { ttsSettings, usingTTS, ttsOnOff, audio } = useTTSSettings();
  useEffect(() => {
    if (audio) audio.pause();
  }, [selectId]);
  const itemList: CustomMenuItemProps[] = [
    ...ttsSettings,
    { message: t('chat.menu.deleteMessage'), onClick: deleteMessageHandler, icon: 'delete' },
    // TODO: 自動読み上げ機能の実装をしたらここを有効にする
    // {
    //   message: '自動読み上げ',
    //   onClick: () => {},
    //   icon: 'volume',
    //   isSwitch: true,
    //   isChecked: switchChecked1st,
    //   onChange: handleSwitchChange1st,
    // },
    // {
    //   message: 'xxxxxx',
    //   onClick: () => {},
    //   icon: 'help',
    //   isSwitch: true,
    //   isChecked: switchChecked2nd,
    //   onChange: handleSwitchChange2nd,
    // },
  ];
  const plugin: CustomMenuItemProps = {
    message: t('chat.menu.webSearch'),
    onClick: () => {
      pluginHandler();
      closeAnchor();
    },
    icon: 'web',
    isSwitch: true,
    isChecked: isWebSearch,
    onChange: pluginHandler,
  };

  // ---------------- meatballs menu

  // recording
  const onRecordingStartButtonClick = useCallback(async () => {
    console.log('録音開始ボタンがクリックされました');
    startRecording();
    await handleRecordingStatus('RECORDING');
  }, []);

  const onRecordingStopButtonClick = useCallback(async () => {
    console.log('録音停止ボタンがクリックされました');
    await stopRecording();
    await handleRecordingStatus('GENERATING');
    // // 2秒間スリープ（遅延）
    // await new Promise(resolve => setTimeout(resolve, 3000));
    console.log('Finished');
    // await handleRecordingStatus('STOPPING')
  }, []);

  // send message
  const updateMessageHandler = useCallback(
    async (message: string, count: number) => {
      if (count === 0 && !selectId) {
        handleChatLog([
          {
            id: 'new',
            title: 'New...',
            version: chatModels[0].versionNum,
            updatedAt: '',
            modelName: chatModels[0].serverSideVersionString,
            isWebSearch: false,
          },
          ...chatLog,
        ]);
        handleSelectId('new');
      }
      dispatchActionUpdate(message);
    },
    [selectId, chatLog],
  );

  // const getOriginBase64 = async (content: string) => {
  //   return await mainChatAPI.getOriginBase64(content.replace('/chat/image/', '')).then((res) => res.data);
  // };

  /*
  イメージを含むメッセージを送る際は
  content: [
            { image_url: { url: content }, type: 'image_url' },
            { text: nextText, type: 'text' },
          ],
          のような形式て送る必要があります。
  だが、データベースには
  イメージは別の行として、textで入ってます（今回データベースは触ってないので、以前のままでの運用になってます。）
  その結果、以前のメッセージはデータベースから取得してる形で、イメージは
          content: [
            { image_url: { url: content }, type: 'image_url' },
            { text: nextText, type: 'text' },
          ],
          この形ではなく
          { role: 'user', content: image_base64_text }
          このように入っていて、
          次のメッセージを送るとtokenエラーが発生してしまいます。
  */
  const chatMessagesFormatting = useCallback(
    async (index?: number) => {
      const formatting: ChatCompletionRequestMessageWithId[] = [];
      for (const [chatIndex, value] of chatMessages.entries()) {
        const content = value.content;
        if (chatIndex === index) break;
        if (typeof content !== 'string' && content !== null && content !== undefined) {
          for (const val of content) {
            if (val.type === 'image_url') {
              let check = '';
              if (val.image_url.url) check = val.image_url.url;
              if (value.originImg) {
                const originItem = value.originImg.find((item) => item.in === check);
                if (originItem) {
                  val.image_url.url = originItem.origin;
                } else {
                  val.image_url.url = check;
                }
              } else val.image_url.url = check;
            }
          }
          formatting.push(value);
        } else formatting.push(value);
      }
      return formatting;
    },
    [chatMessages],
  );

  const handleSendMessageButton = useCallback(
    async (e?: any) => {
      if (e) e.preventDefault();
      dispatch(startLoading());
      // New Chat画面でメッセージを送信したらスナックバーを消す
      closeSnackbar();
      if (images.length > 0 && !Boolean(inputMessage)) return;

      const imageDataForRollback = images;
      const message = inputMessage;

      try {
        // TODO tiktokenの計算をフロントで行うのか？
        const token = await inputTokenLength({ encoding: useChatType.encoding, message: inputMessage });
        // /* TODO 要チェック 3.5の場合はToken上限が4096のはずだがなぜか8192まで行けてしまう */
        if (useChatType.maxTokenLength < tokenLength + token) {
          tokenOverMessage();
          handleMessageToken(token);
          dispatch(stopLoading());
          return;
        }

        // rollback時にイメージのデータを表示するため
        const formattingMessages = (await chatMessagesFormatting()) || [];
        // イメージをチャットに表示
        const resizedImages: OriginImageList[] = [];
        for (const img of images) {
          const resized = await resizeBase64ImageTo128Size(img);
          resizedImages.push({ in: resized, origin: img });
        }

        if (images.length > 0) {
          const value: ChatCompletionContentPart[] = [];
          for (const img of resizedImages) {
            value.push({
              type: 'image_url',
              image_url: {
                url: img.in,
              },
            });
          }
          value.push({
            type: 'text',
            text: message,
          });
          dispatchActionAddUser(value, resizedImages);
        } else {
          // userの送信したメッセージを追加
          dispatchActionAddUser(message);
        }

        // 空のレスポンスを生成する
        dispatchActionAddAssist();
        handleAttachedImages([]);
        handleInputMessage(''); // メッセージ入力フィールドの値をリセット

        const currentLanguage = i18n.language;

        // イメージ含むメッセージの形式
        /*
        role: 'user',
              content: [
                { image_url: { url: images }, type: 'image_url' },
                ,
              ],
        */

        const requestMessage: ChatCompletionMessageParam[] = [];
        if (images.length > 0) {
          const insert: ChatCompletionContentPart[] = [];
          images.forEach((img) => insert.push({ image_url: { url: img }, type: 'image_url' }));
          insert.push({ text: inputMessage, type: 'text' });
          requestMessage.push({ role: 'user', content: insert });
        } else {
          requestMessage.push({ role: 'user', content: inputMessage });
        }

        // OpenAPIに質問を投げる。返ってきた返答をコールバック関数で処理し、リアルタイムにメッセージが出る様に表示
        handleChatEnd(false);
        const id = await submitMessageToOpenApiInSync({
          id: selectId,
          messages: [...formattingMessages, ...requestMessage],
          chatType: useChatType,
          setAbortCntl: handleAbortCntl,
          callback: updateMessageHandler,
          language: currentLanguage,
          purpose: 'chat',
          isWebSearch: isWebSearch,
          ttsFlag: ttsOnOff,
          tts: usingTTS,
          resizedImage: resizedImages,
        });
        if (id) {
          handleSkipCount(skipCount + 1);
          handleSelectId(id);
        }
      } catch (e: any) {
        errorMessage(e);
        handleInputMessage(message);
        // rollback時にイメージは戻して、メッセージボックスに表示、イメージを含んでるのでに段階削除が必要
        if (imageDataForRollback) handleAttachedImages(imageDataForRollback);
        dispatchActionReset();
      } finally {
        dispatch(stopLoading());
        handleChatEnd(true);
        handleMessageToken(0);
      }
    },
    [useChatType, inputMessage, tokenLength, selectId, skipCount, isWebSearch, ttsOnOff, usingTTS, chatLog, images],
  );

  const updateCallback = useCallback(async (message: string, _: number) => {
    dispatchActionUpdate(message);
  }, []);

  const handleSendUpdateMessageButton = useCallback(
    async (e: any, updatedMsg: string, index: number) => {
      e.preventDefault();
      dispatch(startLoading());

      const token = await inputTokenLength({ encoding: useChatType.encoding, message: updatedMsg });

      if (useChatType.maxTokenLength < tokenLength + token) {
        tokenOverMessage();
        handleMessageToken(token);
        dispatch(stopLoading());
        return;
      }

      const showingContent = _.cloneDeep(chatMessages[index]);
      if (showingContent.content && typeof showingContent.content !== 'string') {
        for (const text of showingContent.content) {
          if (text.type === 'text') text.text = updatedMsg;
        }
        dispatchActionEdit(showingContent.content, index);
      } else if (showingContent.content && typeof showingContent.content === 'string') {
        showingContent.content = updatedMsg;
        dispatchActionEdit(showingContent.content, index);
      }

      const item = _.cloneDeep(chatMessages[index]);
      if (item.content && typeof item.content !== 'string') {
        for (const text of item.content) {
          if (text.type === 'text') text.text = updatedMsg;
          if (text.type === 'image_url') {
            let check = '';
            if (text.image_url.url) check = text.image_url.url;
            if (item.originImg) {
              const originItem = item.originImg.find((obj) => obj.in === check);
              if (originItem) {
                text.image_url.url = originItem.origin;
              } else {
                text.image_url.url = check;
              }
            } else text.image_url.url = check;
          }
        }
      } else if (item.content && typeof item.content === 'string') {
        item.content = updatedMsg;
      }

      dispatchActionAddAssist();
      handleInputMessage('');

      const currentLanguage = i18n.language;
      // rollback時にイメージのデータを表示するため
      const formattingMessages = (await chatMessagesFormatting(index)) || [];
      try {
        handleChatEnd(false);
        await submitMessageToOpenApiInSync({
          id: selectId,
          messages: [...formattingMessages, { role: 'user', content: item.content! }],
          chatType: useChatType,
          setAbortCntl: handleAbortCntl,
          callback: updateCallback,
          language: currentLanguage,
          purpose: 'edit',
          isWebSearch: isWebSearch,
          index: index,
          ttsFlag: ttsOnOff,
          tts: usingTTS,
        });
      } catch (e: any) {
        handlingErrorGetPreMessages();
        errorMessage(e);
      } finally {
        dispatch(stopLoading());
        handleChatEnd(true);
        handleMessageToken(0);
      }
    },
    [useChatType, inputMessage, tokenLength, selectId, skipCount, isWebSearch, ttsOnOff, usingTTS, chatLog, chatMessages],
  );
  // const [composing, setComposition] = useState(false);
  // const startComposition = useCallback(() => setComposition(true), []);
  // const endComposition = useCallback(() => setComposition(false), []);
  const placeholder: string = isLoading ? t('chat.text.respondMessage') : t('chat.text.sendMessage');

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputMessage(e.target.value);
  }, []);

  // const onKeyDownHandler = useCallback(
  //   (event: React.KeyboardEvent<HTMLInputElement>) => {
  //     if (event.key === 'Enter' && event.shiftKey) {
  //       // Handle Shift + Enter key press event
  //     } else if (event.key === 'Enter') {
  //       // 何も入力されていない場合、またはメッセージ受信中の場合は送信できない
  //       if (inputMessage.length === 0 || isLoading === true) {
  //         // なにも入力されていない状態でEnterを押した場合は何もしない
  //         event.preventDefault();
  //       } else if (!composing) {
  //         event.preventDefault();
  //         handleSendMessageButton(event);
  //       }
  //     }
  //   },
  //   [inputMessage, isLoading, composing],
  // );
  const itemSizeCheck = useCallback((item: File) => {
    const maxSize = 20 * 1024 * 1024; // 20MB
    if (item.size > maxSize) {
      snackbarHooks.warning(t('chat.message.warning.imageSizeOver'));
      return true;
    }
    return false;
  }, []);
  const itemExtensionCheck = useCallback((fileType: string) => {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/webp', 'image/gif'];
    if (allowedTypes.includes(fileType)) return false;
    snackbarHooks.warning(t('chat.message.warning.imageExtension'));
    return true;
  }, []);
  // イメージを貼り付けるため、clipboard上の最初のイメージのみ
  const handlePaste: React.ClipboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const items = event.clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          if (itemExtensionCheck(items[i].type)) {
            return;
          }
          const blob = items[i].getAsFile();
          if (blob) {
            if (itemSizeCheck(blob)) {
              return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
              if (e.target && typeof e.target.result === 'string') {
                const item = e.target.result;
                if (images.length < ImageNum) {
                  handleAttachedImages([...images, item]);
                }
              }
            };
            reader.readAsDataURL(blob);
          }
        }
      }
    },
    [images],
  );

  // ファイルを入れる際のロジック
  const inputFile: React.ChangeEventHandler = useCallback(
    (value) => {
      const files = (value.target as HTMLInputElement).files;
      if (!files || files.length === 0) {
        return;
      }
      for (const file of files) {
        if (itemExtensionCheck(file.type)) {
          return;
        }
        if (itemSizeCheck(file)) {
          return;
        }
        if (file && file.type.includes('image')) {
          const reader = new FileReader();
          reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target && typeof e.target.result === 'string') {
              const item = e.target.result;
              if (images.length < ImageNum) {
                handleAttachedImages([...images, item]);
              }
            }
          };
          reader.readAsDataURL(file);
        }
      }
      (value.target as HTMLInputElement).value = ''; // input type fileをクリアして、onChangeがいつでも動くように
    },
    [images],
  );

  // イメージクリア
  const imgClearAll: React.MouseEventHandler = useCallback(() => {
    handleAttachedImages([]);
  }, []);

  const imgClearOne = useCallback(
    (item: string) => {
      const list = _.cloneDeep(images);
      list.splice(list.indexOf(item), 1);
      handleAttachedImages(list);
    },
    [images],
  );

  useEffect(() => {
    if (homeMessage) {
      handleSwitchHomeMessage(false);
      handleSendMessageButton();
    }
  }, [homeMessage]);

  /**
   * image resize
   * @param base64 string
   * @returns resized base64
   */
  const resizeBase64ImageTo128Size = async (base64: string): Promise<string> => {
    if (base64 === '') return '';
    return new Promise<string>((resolve, reject) => {
      const img = new Image();
      img.src = base64;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject(new Error('Canvas context is not supported.'));
          return;
        }
        const originalWidth = img.width;
        const originalHeight = img.height;
        const aspectRatio = originalWidth / originalHeight; // 比率

        let newWidth = 128;
        let newHeight = 128;

        if (originalWidth > originalHeight) {
          // 元のファイルの幅が高さより大きい場合、幅を128にして、それに合わせて高さを調整するべき
          newHeight = newWidth / aspectRatio; // 比率は幅を高さで分けたものなので、128を比率でまた分けると128以下の高さの値が出る
        } else {
          newWidth = newHeight * aspectRatio;
        }

        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        resolve(canvas.toDataURL('image/jpeg'));
      };
      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const extension = (contentType: any) => {
    if (typeof contentType === 'string') {
      if (contentType.includes('image/')) return contentType.replace('image/', '');
    }
    return 'png';
  };

  const downloadImg = async (imageLink?: string) => {
    if (imageLink?.startsWith(apiGateway)) {
      const thumbnailId = imageLink.replace(apiGateway + prefixApiGateway + imageOriginAddress, '');
      try {
        const response = await mainChatAPI.downloadImg(thumbnailId);
        const ext = extension(response.headers.contentType);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = `download.${ext}`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (error) {
        console.error('Error downloading the file', error);
      }
    } else {
      downloadBase64File(imageLink || '');
    }
  };

  const downloadBase64File = (base64String: string) => {
    const a = document.createElement('a');
    a.href = base64String;
    a.download = 'download';
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  };

  useEffect(() => {
    if (chatMessages.length === 0 && topicFlag) {
      handleSendMessageButton();
      handleTopicFlag(false);
    }
  }, [topicFlag, chatMessages]);

  return {
    progress,
    tokenLength,
    messageToken,
    newBtnLabel,
    newBtnOnClick,
    meatballsClick: openAnchor,
    anchorEl,
    meatballsClose: closeAnchor,
    itemList,
    onRecordingStartButtonClick,
    onRecordingStopButtonClick,
    handleSendMessageButton,
    handleInputChange,
    // onKeyDownHandler,
    // startComposition,
    // endComposition,
    placeholder,
    handleSendUpdateMessageButton,
    anchorOriginTR,
    transformOriginBR,
    plugin,
    handlePaste,
    // images,
    imgClearAll,
    imgClearOne,
    inputFile,
    downloadImg,
  };
};
export default useMessage;
